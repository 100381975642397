.wrapper {
  .mySwiper {
    height: 100%;
    width: 100%;
    padding-left: 5.5rem;
    padding-right: 5.5rem;
  }

  .swiper-slide {
    min-width: 154px !important;
  }
}

@media (max-width: 768px) {
  // .wrapper .swiper-slide {
  //     width: 280px !important;
  // }

  .wrapper .swiper-container {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
}

@media (max-width: 375px) {
  // .wrapper .swiper-slide {
  //     width: 280px !important;
  // }

  .wrapper .swiper-container {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}
