.ql-container {
  font-size: 1rem !important;
}

.ql-toolbar {
  border-top-right-radius: 8px !important;
  border-top-left-radius: 8px !important;
  border-color: #cdcdcd;
}

.ql-container {
  border-bottom-right-radius: 8px !important;
  border-bottom-left-radius: 8px !important;
  border-color: #cdcdcd;
}

.ql-snow .ql-editor img {
  max-width: 150px;
  height: unset;
}

.invalid {
  .ql-toolbar {
    border-color: #e53e3e;
  }

  .ql-container {
    border-color: #e53e3e;
  }
}
